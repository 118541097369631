import './lazy/mainSlider';
import './lazy/styles';

import './lazy/img';
import './lazy/bg';

import './modules/cadfem';
import './modules/bootstrap';
import './images';

import('./additional/svgRequire');


