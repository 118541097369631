import { LazyObserver } from "cadfem-modules/src/classes";
const selector = '[jumbotronMainSlider]';

const sliderOpts = {
  loop: true,
  navigation: {
    nextEl: '.swiperSlider__arr-next',
    prevEl: '.swiperSlider__arr-prev',
  },
};

(new LazyObserver({
    selector: selector,
    activeClass:'data-jumbotronMainSlider-lazy',
    observe: ({target, IO, activeClass}) => {
      import('swiper/js/swiper.min')
        .then( module => new module.default(selector, sliderOpts) )
        .then( () => target.removeAttribute(activeClass) )
        .catch( error => console.error(error) );
      IO.unobserve(target);
    }
  })
).init();
